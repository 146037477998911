class criteriaService {
    constructor(
        $state,
        $filter,
        http,
        $http,
        tenderService,
        ntFlash,
        loadExternalDataService,
        uuid4,
        md5,
    ) {
        this.uuid4 = uuid4
        this.md5 = md5
        this.ntFlash = ntFlash
        this.http =   $http
        this.tenderService = tenderService
        this.ExternalDataService =   loadExternalDataService;

    }
    setTender(tender){
        this.tender = tender
        this.isSingleLot = false
        this.isMultilotTender = false
        this.tender = this.tenderService.currentTender();
        this.tenderId = this.tender.id;
        angular.copy(this.tender.criteria, this.criteria);
        if(this.tender.criteria) {
            this.criteria = this.tender.criteria.map((el) => {
                el.edit = false;
                return el;
            })

            this.tender.criteria = []
            this.AddedCriteria = true;
        }


        this.isGuaranteeCriteriasActive = false
        this.isContractGuaranteeCriteriasActive = false
        this.isLccCriteriasActive = {
            COST_OF_USE: (this.criteria || []).some(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE'),
            MAINTENANCE_COST: (this.criteria || []).some(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST'),
            END_OF_LIFE_COST: (this.criteria || []).some(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST'),
            ECOLOGICAL_COST: (this.criteria || []).some(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST')
        }
        this.criteriaCheck()

    }

    getCriteria(){
        // this.tender.id

        return this.criteria


    }
    setCriteria(criteria){
        this.criteria = criteria
    }

    addCriteria(criteria) {
        this.criteria = [...this.criteria, ...criteria]
    }

    criteriaCheck(){

        if(this.criteria){
            this.criteria.forEach((el)=>{

                this.setGuaranteeCriterias(el.classification.id, true)

            })
        }
    }

    addCriterias(){
        return this.ExternalDataService.getCriterias()
            .then((data) => {
                let criteria = data

                if(['aboveThreshold', 'competitiveOrdering'].includes(this.tender.procurementMethodType)){
                    let criterias = criteria.filter((el)=>{
                        if(el.classification.id !== 'CRITERION.EXCLUSION.CONTRIBUTIONS.PAYMENT_OF_TAXES'){
                            return el;
                        }
                    })
                    this.criteria = criterias
                    return criterias;
                    // return  this.ExternalDataService.getOtherCriterias()
                    //     .then((o_crit)=>{
                    //         let criteriasLang = o_crit.filter((el)=>el.classification.id === 'CRITERION.OTHER.BID.LANGUAGE')
                    //         criterias = [...criterias, ...criteriasLang]
                    //         this.criteria = criterias
                    //         return criterias;
                    //     })
                } else if (!['aboveThresholdEU', 'aboveThresholdUA.defense', 'simple.defense', 'closeFrameworkAgreementSelectionUA', 'esco'].includes(this.tender.procurementMethodType)){
                    return  this.ExternalDataService.getOtherCriterias()
                        .then((o_crit)=>{

                            this.criteria = [...criteria, ...o_crit].map((crit) => {

                                if(!crit.relatesTo){

                                    crit.relatesTo = 'tenderer'
                                }


                                return {
                                    ...crit,
                                }
                            })

                            criteria = [...criteria, ...o_crit]
                            this.criteria = criteria
                            return criteria;
                        })
                }
                return criteria
            })
            .then((criteria)=>{
                console.log(criteria)
                return this.http.post('/opc/tender/'+ this.tenderId +'/criterias', criteria)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
            });
    }
    addArticle16Criteria(id){
        return this.ExternalDataService.classifierObjections16()
            .then((data) => {
                    const criteria = data.filter((criteria) => criteria.classification.id === id)
                    criteria[0].requirementGroups[0].requirements[0] = {
                        title: this.criteria[0].description,
                        dataType: this.criteria[0].requirementGroups[0].requirements[0].dataType,
                        expectedValue: this.criteria[0].requirementGroups[0].requirements[0].expectedValue,
                        eligibleEvidences: [
                            {
                                type: this.criteria[0].requirementGroups[0].requirements[0].eligibleEvidence.type,
                                title: this.criteria[0].description,
                                description: this.criteria[0].requirementGroups[0].requirements[0].eligibleEvidence.description
                            }
                        ]
                    }

                this.criteria = criteria.map((crit) => {
                    return {
                        ...crit,
                        must_save: true,
                        edit: true,
                        relatesTo: this.getMultilotTender() ? 'lot': 'tender',
                        relatedItem: this.getMultilotTender() ? this.tender.lots[0].id : null,
                    }
                })

                    return this.criteria;
            });

    }


    addLangCriterias(){
        return  this.ExternalDataService.getOtherCriterias()
            .then((o_crit)=>{
                let criteriasLang = o_crit.filter((el)=>el.classification.id === 'CRITERION.OTHER.BID.LANGUAGE')

                // criteriasLang.edit = false
                // this.criteria = [...this.criteria, criteriasLang]
                return criteriasLang;
            })
            .then((criteria)=>{
                return this.http.post('/opc/tender/'+ this.tenderId +'/criterias', criteria)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
            });
    }



    addGuaranteeCriteria() {
       return  this.ExternalDataService.getGuaranteeCriterias()
           .then(data => {
               (data || []).forEach(el => {
                   el.relatesTo = this.getMultilotTender() ? 'lot': 'tender';
                   el.relatedItem = this.getMultilotTender() ? this.tender.lots[0].id : null;
                   el.must_save = true;
                   el.edit = true
               });


               return data
           })


    }



    addLocalOriginLevel() {
       return  this.ExternalDataService.local_origin_level()
           .then((data) => {

              const criteria= (data || []).map(el => {
                   el.relatesTo = 'item';
                   el.relatedItem = this.tender.items[0].id;
                   el.must_save = true;
                   el.edit = true
                  console.log(this.tender.items)
                   el.requirementGroups = el.requirementGroups.map((requirementGroup) => {
                       requirementGroup.requirements = requirementGroup.requirements.map((requirement) => {
                           const updatedRequirement = {
                               ...requirement,
                           }
                           if(requirement.dataType === 'string'){
                               updatedRequirement.eligibleEvidences = [
                                   {
                                       "title": "Документальне підтвердження",
                                       "description": "Довідка в довільній формі",
                                       "type": "document"
                                   }
                               ]
                           }
                           if(requirement.exp){}

                           console.log("!requirement.eligibleEvidence", updatedRequirement)
                           return updatedRequirement

                       })
                       console.log("requirementGroup", requirementGroup)
                       return requirementGroup
                   })
                   return el
               });

                console.log(data)
               return criteria
           })


    }

    addContractGuaranteeCriteria ()  {
       return  this.ExternalDataService.getContractGuaranteeCriterias()
            .then(data => {
                (data || []).forEach(el => {

                    el.relatesTo = this.getMultilotTender() ? 'lot': 'tender';
                    el.relatedItem = this.getMultilotTender() ? this.tender.lots[0].id : null;
                    el.must_save = true;
                    el.edit = true
                });
                return data
            })
    }



    addLccCriteria(id) {
        return  this.ExternalDataService.getLccCriterias()
            .then(data => {
                const criteria = (data || []).find(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.' + id);
                if (criteria) {
                    criteria.id = md5.createHash(uuid4.generate()),
                        criteria.relatesTo = this.tender.lots.length ? 'lot' : 'tender';
                    if (this.isMultilotTender) criteria.relatedItem = '';
                    if (!Array.isArray(this.criteria)) this.criteria = [];
                    this.criteria = [...this.criteria, criteria];
                    this.isLccCriteriasActive[id] = true;
                }
            })
    }

    saveOptionalCriteria(criteria, update = true, edit = false){

        if(update){

            if(criteria.must_save){
                delete criteria.must_save
            }
            if(criteria.edit){
                delete criteria.edit
            }

            criteria.requirementGroups.forEach((requirementGroup)=>{
                requirementGroup.requirements.forEach((requirement)=> {

                    if (requirement.requirementForm) {
                        delete requirement.requirementForm
                    }
                    if (requirement.eligibleEvidences) {
                        requirement.eligibleEvidences.forEach((eligibleEvidence) => {
                            if (eligibleEvidence.eligibleEvidenceForm) {
                                delete eligibleEvidence.eligibleEvidenceForm
                            }
                        })
                    }
                })

            })

            return this.http.post('/opc/tender/'+ this.tenderId +'/criterias/', criteria)
                .then((data)=>{
                    (data.data || []).forEach((el)=>{
                        this.setGuaranteeCriterias(el.classification.id, true);
                    })
                    return data.data
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {

            const promises = [];
            criteria.requirementGroups.forEach((requirementGroup)=>{
                requirementGroup.requirements.forEach((requirement)=>{

                    if(requirement.requirementForm){
                        delete requirement.requirementForm
                    }
                    if(requirement.eligibleEvidences){
                        requirement.eligibleEvidences.forEach((eligibleEvidence)=>{
                            if(eligibleEvidence.eligibleEvidenceForm){
                                delete eligibleEvidence.eligibleEvidenceForm
                            }
                        })
                    }

                    promises.push(this.requestOptionalRequirement(criteria.id, requirementGroup.id, requirement, edit))

                })

            })


            return Promise.all(promises)
                .then((data)=>{
                    console.log(data);
                    return data
                })
        }

    }

    requestOptionalRequirement(criteria_id, group_id, requirement, edit = false){

        const data = {
            description: requirement.description
        }
        let link = `/opc/tender/${this.tenderId}/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement.id}/`

        if(edit){
            return this.http.put(link, data)
                .then((data)=>{
                    return data
                })


        } else {
            return this.http.patch(link, data)
                .then((data)=>{
                    return data
                })

        }

    }

    deleteRequirement(criteria_id, group_id, requirement_id, edit){


        let link = `/opc/tender/${this.tenderId}/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/`
        const data = {status: 'cancelled'};

        if(edit){
            return this.http.put(link, data)
                .then((data)=>{
                    return data
                })
        } else {
            return this.http.patch(link, data)
                .then((data)=>{
                    return data
                })
        }

    }

    restoreRequirement(criteria_id, group_id, requirement_id, edit){

        let link = `/opc/tender/${this.tenderId}/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/`

        let data = {status: 'active'}

        // return this.http.patch(link, data)
        //         .then((data)=>{
        //             console.log(data)
        //         })


        if(edit){
            return this.http.put(link, data)
                .then((data)=>{
                    return data
                })
        } else {
            return this.http.patch(link, data)
                .then((data)=>{
                    return data
                })
        }

    }



    // deleteOptionalCriteria(criteria){}


    isOptional(criteria)  {
        return [

            'CRITERION.OTHER.SUBJECT_OF_PROCUREMENT.LOCAL_ORIGIN_LEVEL',
            'CRITERION.OTHER.BID.GUARANTEE',
            'CRITERION.OTHER.CONTRACT.GUARANTEE',
            'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE',
            'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST',
            'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST',
            'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
            'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'

        ].includes(criteria.classification.id);
    }

    isLcc(criteria)  {
        return [
            'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE',
            'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST',
            'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST',
            'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST'
        ].includes(criteria.classification.id);
    }



    editDraftEligibleEvidence(type= 'create', criteria_id, group_id, requirement_id, evidence_id = null, data ){
        let resource = ''
        if(evidence_id){
            resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/evidences/${evidence_id}/`;
        } else {
            resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/evidences/`;
        }

        let request;
        switch (type){
            case 'create': {
                request =  this.http.post(resource, data)
            }
            break;
            case 'edit_draft': {
                request =  this.http.patch(resource, data)
            }
            break;
            case 'delete': {
                request =   this.http.delete(resource)
            }
            break;
        }


        return request
            .then((response) => {
                return response
            })

    }


    editActiveEligibleEvidence(type= 'create', criteria_id, group_id, requirement,  data, evidence_id = null ){
        let resource = ''
        let request;

        switch (type){

            case 'delete': {
                resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement.id}/evidences/${evidence_id}/`;
                request =   this.http.delete(resource)
            }
                break;
            default: {
                resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement.id}/`;
                request =  this.http.put(resource, data)
            }
        }


        return request
            .then((response) => {
                return response
            })

    }




        // return this.http.patch('/opc/tender/'+ this.tenderId +'/criterias/' + criteria.id+ '/', data)
        //     .then((data)=>{
        //         console.log(data)
        //         return data.data
        //     })
        //     .catch(error => {
        //         console.log('criteriaService.saveCriterias error', error);
        //     })

    // }


    setGuaranteeCriterias(name, status){
        switch (name) {
            case 'CRITERION.OTHER.BID.GUARANTEE': this.isGuaranteeCriteriasActive = status; break;
            case 'CRITERION.OTHER.CONTRACT.GUARANTEE': this.isContractGuaranteeCriteriasActive = status; break;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE': this.isLccCriteriasActive.COST_OF_USE = status; break;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST': this.isLccCriteriasActive.MAINTENANCE_COST = status; break;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST': this.isLccCriteriasActive.END_OF_LIFE_COST = status; break;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST': this.isLccCriteriasActive.ECOLOGICAL_COST = status; break;
        }
    }

    getGuaranteeCriterias(name){
        console.log(this.isContractGuaranteeCriteriasActive)
        switch (name) {
            case 'CRITERION.OTHER.BID.GUARANTEE': return this.isGuaranteeCriteriasActive;
            case 'CRITERION.OTHER.CONTRACT.GUARANTEE': return this.isContractGuaranteeCriteriasActive;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE': return this.isLccCriteriasActive.COST_OF_USE;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST': return this.isLccCriteriasActive.MAINTENANCE_COST;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST': return this.isLccCriteriasActive.END_OF_LIFE_COST;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST': return this.isLccCriteriasActive.ECOLOGICAL_COST;
        }
    }


    setSingleLot(status){
        this.isSingleLot = status
    }

    getSingleLot(){
        // console.log(this.isSingleLot);
        return this.isSingleLot
    }


    setMultilotTender(status){
        this.isMultilotTender = status
    }

    getMultilotTender(){
        // console.log(this.isMultilotTender);
        return this.isMultilotTender
    }

}
commons.service('criteriaService', criteriaService);
